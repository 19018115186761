<template>
  <div class="container">
    <div class="row">
      <div
        class="alert alert-danger"
        v-for="(error, index) in errors"
        :key="index"
      >
        <p v-for="errorMess in error" :key="errorMess">{{ errorMess }}</p>
      </div>
    </div>
    <div class="row my-5" v-show="boards">
      <div class="col-12 col-md-8">
        <select class="form-select my-3" v-model="selBoard">
          <option :value="board.id" v-for="board in boards" :key="board.id">
            {{ board.name }}
          </option>
        </select>
        <div class="row justify-content-evenly">
          <div class="col-6 d-flex justify-content-evenly">
            <a class="btn btn-outline-dark" @click="changeYear(-1)">
              <i class="bi bi-caret-left-fill my-icons"></i>
            </a>
            <div class="my-auto">{{ year }}</div>
            <a class="btn btn-outline-dark" @click="changeYear(1)">
              <i class="bi bi-caret-right-fill my-icons"></i>
            </a>
          </div>
          <div class="col-6 d-flex justify-content-evenly">
            <a class="btn btn-outline-dark" @click="changeMonth(-1)">
              <i class="bi bi-caret-left-fill my-icons"></i>
            </a>
            <div class="my-auto">{{ month.name }}</div>
            <a class="btn btn-outline-dark" @click="changeMonth(1)">
              <i class="bi bi-caret-right-fill my-icons"></i>
            </a>
          </div>
        </div>

        <div class="container my-5">
          <h4 class="title my-4">{{ month.title }}</h4>
          <table class="table text-center">
            <thead>
              <tr>
                <th>Mon</th>
                <th>Tue</th>
                <th>Wed</th>
                <th>Thu</th>
                <th>Fri</th>
                <th class="text-danger">Sat</th>
                <th class="text-danger">Sun</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="day"
                v-for="(week, index) in calendar.weeks"
                :key="index"
              >
                <template v-for="day in 7">
                  <td
                    :key="'have' + day"
                    v-if="week[day]"
                    v-bind:date="week[day].str"
                    @click="getRemindsByDate(week[day].str)"
                    class="remind"
                    :class="{ 'bg-danger': week[day].haveReminds }"
                  >
                    {{ week[day].date.getDate() }}
                  </td>
                  <td v-else :key="'not' + day"></td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="container-fluid">
          <h3>Create a reminder</h3>
          <div class="form my-3">
            <div class="form-groupmy-3">
              <label for="record_name">Subject</label>
              <input
                type="text"
                v-model="newRemind.name"
                class="form-control"
                id="record_name"
              />
            </div>
            <div class="form-group my-3">
              <label for="record_name">Reminder date</label>
              <input
                type="date"
                v-model="newRemind.date"
                class="form-control"
                id="record_date"
                @change="getRemindByDate"
              />
            </div>
            <div class="form-group my-3">
              <label for="record_name">Reminder time</label>
              <input
                type="time"
                v-model="newRemind.time"
                class="form-control"
                id="record_date"
                @change="getRemindByDate"
              />
            </div>
            <div class="form-group my-3">
              <label for="record_desc">Reminder text</label>
              <textarea
                v-model="newRemind.description"
                class="form-control"
                id="record_desc"
                rows="3"
              ></textarea>
            </div>
          </div>

          <button @click="addRemind" class="btn btn-primary col-6">
            Save
          </button>
        </div>
        <div class="container-fluid mt-5" v-if="date&&Object.keys(reminds).length!=0">
          <h3>Date {{ date }}</h3>
          <div class="accordion accordion-flush" id="accordionFlushExample">
            <div
              class="accordion-item"
              v-for="(remind, index) in reminds"
              :key="remind.id"
            >
              <h2
                class="accordion-header"
                :id="'flush-headingOne_' + remind.id"
              >
                <button
                  class="accordion-button collapsed bg-primary text-light"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#flush-collapseOne_' + remind.id"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  {{ remind.name }} {{ remind.remind_time }}
                </button>
              </h2>
              <div
                :id="'flush-collapseOne_' + remind.id"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body border">
                  <input
                    type="text"
                    v-model="remind.name"
                    class="form-control my-2"
                    :disabled="!remind.edite"
                  />
                  <textarea
                    v-model="remind.description"
                    class="form-control my-2"
                    rows="3"
                    :disabled="!remind.edite"
                  ></textarea>
                  <a
                    class="btn btn-primary mx-1"
                    v-if="!remind.edite"
                    @click="remind.edite = !remind.edite"
                  >
                    <i class="bi bi-pencil"></i>
                  </a>

                  <a
                    class="btn btn-primary mx-1"
                    @click="saveRemind(remind.id,index)"
                    v-if="remind.edite"
                  >
                    <i class="bi bi-save"></i>
                  </a>
                  <a class="btn btn-danger" @click="deleteRemind(remind.id)"><i class="bi bi-trash"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("en");

export default {
  name: "Calendar",
  data() {
    return {
      boards: [],
      selBoard: "",
      year: null,
      month: {},
      newRemind: {
        name: "",
        description: "",
        date: null,
        time: null,
      },
      reminds: {},
      errors: {},
      remindsAtMonth: [],
      calendar: {},
      date: null,
    };
  },
  methods: {
    //Change the reminder
    async saveRemind(id, index) {
      this.errors = [];
      await this.API.put("/reminds/" + id, {
        name: this.reminds[index].name,
        description: this.reminds[index].description,
      })
        .then((response) => {
          this.reminds[index].edite = !this.reminds[index].edite;
          alert(response.data.message);
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.errors = error.response.data.errors;
          }
        });
    },

    //Deleting a reminder
    async deleteRemind(id) {
      this.errors = [];

      if (confirm("Вы уверены?")) {
        await this.API.delete("/reminds/" + id)
          .then((response) => {
            alert(response.data.message);
            this.getRemindsByMonth();
            this.getRemindsByDate(this.date);
          })
          .catch((error) => {
            if (error.response.data.errors) {
              this.errors = error.response.data.errors;
            } else {
              alert(error.response.data.message);
            }
          });
      }
    },
    //Drawing a calendar
    renderDates() {
      this.calendar = this.month;
      for (let week in this.calendar.weeks) {
        for (let day in this.calendar.weeks[week]) {
          if (
            this.remindsAtMonth.includes(this.calendar.weeks[week][day].str)
          ) {
            this.calendar.weeks[week][day].haveReminds = true;
          } else {
            this.calendar.weeks[week][day].haveReminds = false;
          }
        }
      }
    },
    //Getting dates with reminders in a month
    getRemindsByMonth() {
      if (this.month.number && this.year && this.selBoard) {
        this.errors = [];
        this.remindsAtMonth = [];
        this.API.get("/reminds/month", {
          params: {
            month: this.month.number,
            year: this.year,
            board: this.selBoard,
          },
        })
          .then((response) => {
            this.remindsAtMonth = response.data.dates;
            this.remindsAtMonth = Object.values(this.remindsAtMonth);
            this.renderDates();
          })
          .catch((error) => {
            if (error.response.data.errors) {
              this.errors = error.response.data.errors;
            }
          });
      }
    },
    //Getting a list of reminders on a selected day
    async getRemindsByDate(date) {
      this.errors = [];
      this.reminds = {};
      this.API.get("/reminds", {
        params: {
          date: date,
          board: this.selBoard,
        },
      })
        .then((response) => {
          this.date = date;
          for (let remind in response.data.reminds) {
            response.data.reminds[remind]["edite"] = false;
          }
          this.reminds = response.data.reminds;
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.errors = error.response.data.errors;
          }
        });
    },
    //Adding a reminder
    async addRemind() {
      this.errors = [];

      await this.API.post("/reminds", {
        remind: this.newRemind,
        board: this.selBoard,
      })
        .then((response) => {
          if(this.date==this.newRemind.date){
            this.getRemindsByDate(this.date);
          }

          this.newRemind = {
            name: "",
            description: "",
            date: null,
            time: null,
          };
          this.getRemindsByMonth();
          alert(response.data.message);
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.errors = error.response.data.errors;
          }
        });
    },
    //Year change
    changeYear(step) {
      this.year += step;
      this.getWeeks();
    },
    //Change month
    changeMonth(step) {
      let newMonth = (this.month.number + (12 - 1) + step) % 12;

      this.month = {
        name: moment({ month: newMonth }).format("MMM"),
        number: +moment({ month: newMonth }).format("M"),
        weeks: {},
      };
      this.getWeeks();
    },
    //Getting a list of available boards
    getBoards() {
      this.API.get("/boards")
        .then((response) => {
          this.boards = response.data.boards;
          this.selBoard = this.boards[0].id;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //Calendar formation
    getWeeks: function () {
      let day = moment({
        year: this.year,
        month: this.month.number - 1,
        day: 1,
      });
      let thisyear = this.year;
      let daysInMonth = day.daysInMonth(); 

      let month = {
        weeks: {},
      };

      for (let d = 0; d < daysInMonth; ++d) {
        let week = day.week();
        if (this.month.number - 1 === 0 && week === 52) {
          week = 0;
        }

        if (!Object.prototype.hasOwnProperty.call(month.weeks, week)) {
          month.weeks[week] = {};
        }

        month.weeks[week][day.weekday() + 1] = {
          date: day.toDate(),
          str: this.year + "-" + day.format("MM") + "-" + day.format("DD"),
        };


        day.add(1, "d");
      }

      this.month.weeks = month.weeks;
    },
  },
  mounted() {
    //Initialization of initial data
    this.getBoards();
    this.year = +moment().format("YYYY");
    this.month = {
      name: moment().format("MMM"),
      number: +moment().format("M"),
      weeks: {},
    };
    this.getWeeks();
  },
  watch: {
    year: function () {
      this.getRemindsByMonth();
    },
    month: function () {
      this.getRemindsByMonth();
    },
    selBoard: function () {
      this.getRemindsByMonth();
    },
  },
};
</script>

<style>
.my-icons {
  font-size: 25px;
}
.remind:hover {
  background: rgb(240, 236, 234);
  cursor: pointer;
}

.have-remind {
  border-left: 2px solid red;
}
</style>