import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Calendar.vue'
import Login from '../components/Login.vue'

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },

    {
        path: '/permission-list',
        name: 'PermissionList',
        component: () =>
            import ('../components/PermissionList.vue'),
        meta: {
            role: 'Admin',
        }
    },
    {
        path: '/boards-list',
        name: 'BoardsList',
        component: () =>
            import ('../components/BoardsList.vue'),
        meta: {
            role: 'Admin',
        }
    },
    {
        path: '/users-list',
        name: 'UsersList',
        component: () =>
            import ('../components/UsersList.vue'),
        meta: {
            role: 'Admin',
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
})

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    if (!token || !user) {
        if (to.name == 'Login') {
            next();
        } else {
            next({
                name: 'Login'
            });
        }
    } else {
        if (to.meta.role) {
            if (user.role.name == to.meta.role) {
                if (to.name == 'Login') {
                    next({
                        name: 'Home'
                    });
                } else {
                    next();
                }
            } else {
                next({
                    name: 'Home'
                });
            }
        } else {
            if (to.name == 'Login') {
                next({
                    name: 'Home'
                });
            } else {
                next();
            }
        }
    }
})

export default router