<template>
  <header
    class="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom"
  >
    <a
      href="/"
      class="
        d-flex
        align-items-center
        mb-3 mb-md-0
        me-md-auto
        text-dark text-decoration-none
      "
    >
      <svg class="bi me-2" width="40" height="32">
        <use xlink:href="#bootstrap"></use>
      </svg>
      <span class="fs-4">Telegram Reminder</span>
    </a>

      <Menu :user="user" v-if="isAuth"></Menu>
    

    <a v-if="token" class="btn btn-danger mx-2" @click="logout()">Logout</a>
  </header>
  <router-view @loginUpdate="getToken" />
</template>

<script>

import Menu from './components/menu/Menu.vue'

export default {
  components:{
    Menu
  },
  data() {
    return {
      token: null,
      user:null,
    };
  },
  methods: {
    getToken() {
      this.token = localStorage.getItem("token");
      this.user = JSON.parse(localStorage.getItem("user"));
    },
    logout() {
      this.HTTP.post("/logout")
        .then(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          this.getToken();
          this.$router.push("/login");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    isAuth(){
      return this.token;
    }
  },
  created() {
    this.getToken();
  },
};
</script>

<style>
</style>
