<template>
  <ul class="nav nav-pills px-2">
    <li class="nav-item">
      <router-link class="nav-link" to="/">Main</router-link>
    </li>
    <template v-if="user.role.name == 'Admin'">
      <li class="nav-item">
        <router-link class="nav-link" to="/boards-list">Boards</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/users-list"
          >Users</router-link
        >
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/permission-list"
          >Access</router-link
        >
      </li>
    </template>
  </ul>
</template>

<script>
export default {
  props: ["user"],
  data() {
    return {};
  },
};
</script>

<style >
</style>